import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Post, Get } from '../../Layout/ajax';
import moment from "moment";
import { Link } from 'react-router-dom';
import { immediateToast } from "izitoast-react";
import { Bookmark } from 'react-feather';
import { current } from '@reduxjs/toolkit';
import { ButtonGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import Pagination from "react-js-pagination";


const Notification = () => {
    const [data, setData] = useState([]);
    const [isGridView, setIsGridView] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    const [notificationfilter, setnotificationfilter] = useState("false");

    useEffect(() => {
        getPost();
    }, [pageNo, perPage, notificationfilter]);
    useEffect(() => {
        getPost();
    }, []);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getPost = async () => {
        let skipa = perPage * (pageNo - 1);
        Post(`notification/getAllNotificationByUId`, { "userId": JSON.parse(window.localStorage.getItem('Id')), skip: skipa, per_page: perPage, filter: notificationfilter })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const markread = async (val) => {
        Post(`notification/markasread`, { "id": val })
            .then((res) => {
                if (res.success == true) {
                    getPost();
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const markreadall = async () => {
        Post(`notification/markasallread`)
            .then((res) => {
                if (res.success == true) {
                    getPost();
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    return (
        <><div className='content-wrapper page'>
            <div className="row align-items-center">
                <div className="col-md-4 col-lg-9">
                    <h2>Notification</h2>
                    <div className='mt-2'>
                        <input className="ml-2" type="radio" name="notificationfilter" value="All"
                            checked={notificationfilter === ""} onChange={() => {setPageNo(1); setnotificationfilter("")}}
                        />
                        <span className="ml-2">All</span>
                        <input className="ml-2" type="radio" name="notificationfilter" value="Read"
                            checked={notificationfilter === "true"} onChange={() => {setPageNo(1); setnotificationfilter("true")}}
                        />
                        <span className="ml-2">Read</span>
                        <input className="ml-2" type="radio" name="notificationfilter" value="Unread"
                            checked={notificationfilter === "false"} onChange={() => {setPageNo(1); setnotificationfilter("false")}}
                        />
                        <span className="ml-2">Unread</span>
                    </div>
                </div>
               
                <div className='col-md-5 col-lg-3 text-end'>
                    <span className="btn btn-sm btn-success mr-3" onClick={() => markreadall()}> Read all notification</span>
                    <span className="">
                        <span>
                            <ButtonGroup>
                                <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                </Btn>
                                <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                </Btn>
                            </ButtonGroup>
                        </span>
                    </span>
                </div>
                
                
            </div>
            <div className='bookmark-tabcontent'>
                <div className={"details-bookmark" + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-lg-6">
                                <div className={item.Isread == false ? 'boxShadow mb-3 p-3 bookmark-card bg-unread' : 'boxShadow mb-3 p-3 bookmark-card'}
                                    onClick={() => item.Isread == false ? markread(item._id) : ''}
                                >
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <div className='mb-1'>
                                                    <Link to={`/${item.link}`}><span><i className="fa fa-sticky-note text-primary mr-2" />{item.description}</span></Link>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-calendar text-success mr-2" /> {moment(item.date).format("DD-MMM-YYYY")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>
        </div>
        </>
    );
}
export default Notification;